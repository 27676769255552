<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col>
          Dispositivo Wifi - {{ getDevice ? getDevice.name : "Não encontrado" }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn @click="reconnect">Reconectar</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <!-- saidas -->
      <v-card outlined class="mt-2">
        <v-card-title>
          <v-row>
            <v-col> Saidas - {{ outputsState.length }} </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="success" @click="send('OUTPUT')">Atualizar</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="n in outputsState.length" :key="n" cols="6">
              <v-card outlined>
                <v-card-title>
                  Saída {{ n }}
                  {{ outputsState[n - 1] == 1 ? "Ligada" : "Desligada" }}
                </v-card-title>
                <v-card-text class="d-flex justify-center mb-6">
                  <div class="switchSVG" @click="sendOut(n - 1)">
                    <div class="circle"></div>
                    <div
                      class="line"
                      :class="
                        outputsState[n - 1] == 0 ? 'lineOpen' : 'lineClose'
                      "
                      ref="linha"
                    ></div>
                    <div class="circle margin"></div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- entradas -->
      <v-card outlined class="mt-2">
        <v-card-title>
          <v-row>
            <v-col> Entradas - {{ inputsState.length }} </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="success" @click="send('INPUT')">Atualizar</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="n in inputsState.length" :key="n" cols="6">
              <v-card outlined>
                <v-card-title>
                  Entrada {{ n }}
                  {{ inputsState[n - 1] == 0 ? "Aberta" : "Fechada" }}
                </v-card-title>
                <v-card-text class="d-flex justify-center mb-6">
                  <div class="switchSVG">
                    <div class="circle"></div>
                    <div
                      class="line"
                      :class="
                        inputsState[n - 1] == 0 ? 'lineOpen' : 'lineClose'
                      "
                      ref="linha"
                    ></div>
                    <div class="circle margin"></div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- card do servidor -->
      <v-card outlined class="mt-2">
        <v-card-title>
          <v-row>
            <v-col> Configuração do servidor </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="success" @click="send('SERVER')">Atualizar</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="serverConfig[0]"
                label="Endereço do server"
                presistent-hint
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                v-model.number="serverConfig[1]"
                label="Porta do server"
                presistent-hint
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="serverConfig[2]"
                label="Path da conexão"
                presistent-hint
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                :items="[
                  { value: '0', text: 'Desativado' },
                  { value: '1', text: 'Ativado' },
                ]"
                label="Estado"
                outlined
                v-model="serverConfig[3]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="serverConfig[4]"
                label="DeviceId"
                presistent-hint
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn x-large @click="send('REBOOT')" class="mx-2" color="error"
              >Reiniciar</v-btn
            >
            <v-btn
              x-large
              @click="send('SERVER=' + serverConfig.join(','))"
              color="warning"
              >Enviar</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
      <!-- card do wifi -->
      <v-card outlined class="mt-2">
        <!-- titulo do card -->
        <v-card-title>
          <v-row>
            <v-col> Configuração do wifi </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="success" @click="send('WIFI')">Atualizar</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <!-- corpo do card -->
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="wifiState[0]"
                label="Nome da Rede"
                presistent-hint
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                v-model.number="wifiState[1]"
                label="Senha"
                presistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="wifiStatesNames[wifiState[2]]"
                label="Estado da Rede"
                presistent-hint
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                v-model="wifiState[3]"
                label="IP"
                presistent-hint
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-show="netWorks.length">
            <v-col>
              <v-simple-table class="mt-2">
                <thead>
                  <tr>
                    <th class="text-left">SSID</th>
                    <th class="text-left">RSSI</th>
                    <th class="text-left">USAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(net, idx) in netWorks"
                    :key="idx"
                    v-show="net.ssid"
                  >
                    <td>{{ net.ssid }}</td>
                    <td>{{ net.rssi }}</td>
                    <td>
                      <v-icon @click="wifiState[0] = net.ssid" class="m-1">
                        mdi-play
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn x-large @click="send('SCAN')" color="success"
              >Buscar Redes</v-btn
            >
            <v-btn x-large @click="send('REBOOT')" class="mx-2" color="error"
              >Reiniciar</v-btn
            >
            <v-btn
              x-large
              @click="send('WIFI=' + wifiState[0] + ',' + wifiState[1])"
              color="warning"
              >Enviar</v-btn
            >
          </v-row>
          <v-row>
            <v-col>
              State: <small>{{ wifists }}</small>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- card dos audios -->
      <v-card outlined class="mt-2">
        <!-- titulo do card -->
        <v-card-title>
          Audios
          <v-spacer></v-spacer>
          <!-- <v-col cols="auto"> -->
          <v-btn color="success" @click="showAudioConverter = true" class="mx-2"
            >Otimizador de Audio</v-btn
          >
          <v-btn color="success" @click="send('AUDIOS')">Atualizar</v-btn>
        </v-card-title>
        <!-- corpo do card -->
        <v-card-text>
          <!-- linha do espaço -->
          <v-row v-show="space.pct > 0">
            <v-col>
              <v-input hint="Espaço utilizado" persistent-hint>
                <v-progress-linear
                  color="light-blue"
                  :value="space.pct"
                  height="20"
                  rounded
                  striped
                  >{{ space.pct.toFixed(2) + "%" }}</v-progress-linear
                >
              </v-input>
            </v-col>
          </v-row>
          <!-- linha do progresso -->
          <v-row v-show="uploadProgress > 0">
            <v-col>
              <v-input hint="Progresso do envio" persistent-hint>
                <v-progress-linear
                  color="light-blue"
                  height="20"
                  rounded
                  :value="uploadProgress"
                  striped
                  >{{ uploadProgress.toFixed(2) + "%" }}</v-progress-linear
                >
              </v-input>
            </v-col>
          </v-row>
          <!-- input de arquivo -->
          <v-row>
            <v-col>
              <!-- hide-input -->
              <v-file-input
                show-size
                outlined
                label="Adicionar Audio"
                clearable
                accept=".mp3"
                @change="addAudioWS"
                truncate-length="15"
              ></v-file-input>
            </v-col>
          </v-row>
          <!-- tabela de audios -->
          <v-row v-show="audios.length">
            <v-col>
              <v-simple-table class="mt-2">
                <thead>
                  <tr>
                    <th class="text-left">Nome</th>
                    <th class="text-left">Tamanho</th>
                    <th class="text-left">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(au, idx) in audios" :key="idx" v-show="au.name">
                    <td>{{ au.name }}</td>
                    <td>{{ au.size }}</td>
                    <td>
                      <v-btn
                        color="success"
                        class="mx-2"
                        @click="playAudioDev(au.name)"
                      >
                        Tocar Equipamento
                      </v-btn>
                      <v-btn
                        color="error"
                        @click="send('LOCALDELETE=' + au.name)"
                      >
                        Apagar do Equipamento
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row v-show="audios.length">
            <v-col cols="auto">
              <v-btn color="warning" @click="send('STOP')">Parar Audio</v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="success" @click="updateDevice"
                >Usar esses audios</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- card do volume do audio -->
      <v-card outlined class="mt-2">
        <!-- titulo do card -->
        <v-card-title>
          Volume do Audio
          <v-spacer></v-spacer>
          <v-btn color="success" @click="send('VOLUME')">Atualizar</v-btn>
        </v-card-title>
        <!-- corpo do card -->
        <v-card-text>
          <v-row v-if="volume > 0">
            <v-col>
              <v-slider
                prepend-icon="mdi-volume-high"
                thumb-label="always"
                hint="Volume do audio"
                max="10"
                min="1"
                persistent-hint
                v-model="volume"
                @change="send('VOLUME=' + volume)"
              ></v-slider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- card do console -->
      <v-card outlined class="mt-2">
        <!-- titulo do card -->
        <v-card-title> Console do equipamento </v-card-title>
        <!-- corpo do card -->
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea
                v-model="textConsole"
                presistent-hint
                outlined
                ref="console"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="toDevice"
                label="Enviar dados"
                presistent-hint
                @keydown="parseTecla"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn x-large @click="sendConsole" color="warning">Enviar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <AudioConverter v-model="showAudioConverter" target="sirenemp3" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AudioConverter from "@/components/AudioConverter.vue";
export default {
  name: "DeviceScreenWifi",
  props: {},
  components: {
    AudioConverter,
  },
  data: () => ({
    ws: null,
    wsUrl: "",
    config: {},
    inputs: 0,
    inputsState: [],
    outputs: 0,
    outputsState: [],
    wifiState: [],
    wifiStatesNames: [
      "mudança entre os status",
      "SSID não encontrado",
      "",
      "CONECTADO",
      "Falhou",
      "",
      "Senha Invalida",
      "DESCONECTADO",
    ],
    netWorks: [],
    ips: [],
    serverConfig: [],
    showSerialPort: false,
    toDevice: "",
    textConsole: "",
    audios: [],
    audiotoSend: null,
    player: null,
    space: {
      pct: 0,
    },
    uploadProgress: 0,
    sending: false,
    parcelado: {},
    showAudioConverter: false,
    volume: 0,
    sended: [],
    historyIndex: 0,
    wifists: "",
  }),
  computed: {
    ...mapGetters(["getDevice"]),
  },
  methods: {
    startWs() {
      this.wsUrl = "";
      if (process.env.NODE_ENV === "production") {
        // /api/device/control/:id
        this.wsUrl =
          `${window.location.protocol}//${window.location.host}/api/device/control/${this.getDevice.id}`
            .replace("http", "ws")
            .replace("https", "wss");
      } else {
        this.wsUrl =
          `${this.$http.defaults.baseURL}device/control/${this.getDevice.id}`
            .replace("http", "ws")
            .replace("https", "wss");
      }
      this.wsUrl += this.$store.getters.getImgTokenQuery;
      this.ws = new WebSocket(this.wsUrl);
      this.ws.onmessage = this.receive;
    },
    receive(message) {
      this.appendConsole("RECEBEU: " + message.data);
      let data = message.data.split("=");
      // configuração da placa
      if (data[0] == "GPIO") {
        let gpio = data[1].split(",");
        if (Array.isArray(gpio) && gpio.length >= 2) {
          this.inputs = parseInt(gpio[0]);
          this.outputs = parseInt(gpio[1]);
        }
      }
      // estado das saídas
      if (data[0] == "OUTPUT") {
        // transforma a string em array
        let gpio = data[1].split("");
        if (Array.isArray(gpio)) {
          this.outputsState = [];
          gpio.forEach((e) => {
            this.outputsState.push(parseInt(e));
          });
        }
      }
      // estado das entradas
      if (data[0] == "INPUT") {
        let gpio = data[1].split("");
        if (Array.isArray(gpio)) {
          this.inputsState = [];
          gpio.forEach((e) => {
            this.inputsState.push(parseInt(e));
          });
        }
      }
      // estado das entradas
      if (data[0] == "WIFI") {
        let gpio = data[1].split(",");
        if (Array.isArray(gpio)) {
          this.wifiState = gpio;
        }
      }
      // config do server
      if (data[0] == "SERVER") {
        let gpio = data[1].split(",");
        if (Array.isArray(gpio)) {
          this.serverConfig = gpio;
        }
      }
      if (data[0] == "SCAN") {
        let gpio = data[1].split(",");
        if (Array.isArray(gpio)) {
          this.netWorks = [];
          // this.netWorks = gpio;
          gpio.forEach((e) => {
            let net = e.split("||");
            this.netWorks.push({
              ssid: net[0],
              rssi: net[1],
            });
          });
          this.netWorks = this.netWorks.sort((a, b) => {
            return b.rssi - a.rssi;
          });
        }
      }
      if (data[0] == "AUDIOS") {
        let gpio = data[1].split(",");
        if (Array.isArray(gpio)) {
          this.audios = [];
          gpio.forEach((e) => {
            let audios = e.split("||");
            if (
              audios.length >= 2 &&
              (audios[0].endsWith(".mp3") || audios[0].endsWith(".MP3"))
            ) {
              this.audios.push({
                name: audios[0],
                size: audios[1],
              });
            }
          });
        }
        this.send("SPACE");
      }
      if (data[0] == "FILES") {
        let gpio = data[1].split(",");
        if (Array.isArray(gpio)) {
          this.audios = [];
          gpio.forEach((e) => {
            let audios = e.split("||");
            if (audios.length >= 2) {
              this.audios.push({
                name: audios[0],
                size: audios[1],
              });
            }
          });
        }
        this.send("SPACE");
      }
      if (data[0] == "UPLOAD" && data[1] == "OK") {
        this.send("AUDIOS");
      }
      if (data[0] == "LOCALDELETE" && data[1] == "OK") {
        this.send("AUDIOS");
      }
      if (data[0] == "VOLUME") {
        this.volume = parseInt(data[1]);
      }
      if (data[0] == "SLICE") {
        this.sendParcelado();
      }
      if (data[0] == "SPACE") {
        let ss = data[1].split(",");
        let sss = {};
        ss.forEach((e) => {
          let ee = e.split("||");
          sss[ee[0].toLowerCase()] = parseInt(ee[1]);
        });
        if (sss.total && sss.used) {
          sss.pct = Math.round((sss.used / sss.total) * 100);
        }
        this.space = sss;
        // this.$refs.progressbar.style.width = this.space.pct + "%";
      }
      if (data[0] == "ERROR COMMAND NOT FOUND") {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showErrorSnack",
          `COMANDO NÃO ENCONTRADO: ${data[1]}`
        );
      }
      if (data[0] == "WIFISTS") {
        this.wifists = data[1];
      }
    },
    send(msg) {
      if (this.ws) {
        this.appendConsole("ENVIOU: " + msg);
        this.ws.send(msg);
      }
    },
    sendOut(pin, state) {
      let str = "OUTPUT=" + pin;
      if (state != undefined) {
        str += "," + state;
      }
      this.send(str);
    },
    disconnect() {
      if (this.ws) {
        this.ws.removeEventListener("message", this.receive);
        this.ws.close();
      }
    },
    reconnect() {
      if (this.getDevice.id) {
        this.disconnect();
        setTimeout(() => {
          this.startWs();
        }, 1000);
      }
    },
    appendConsole(str) {
      let lines = this.textConsole.split("\n");
      if (lines.length > 50) {
        lines.shift();
        this.textConsole = lines.join("\n");
      }
      this.textConsole += `${new Date().toLocaleString()} || ${str}\n`;
      let textarea = this.$refs.console.$el.querySelector("textarea");
      textarea.scrollTop = textarea.scrollHeight;
    },
    playAudioDev(filename) {
      this.send("LOCAL=" + filename);
    },
    async delay(ms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, ms);
      });
    },
    async addAudioWS(event) {
      this.uploadProgress = 0;
      if (!event) return;
      console.log("addAudioWS", event);
      if (event.type != "audio/mpeg") {
        alert("Arquivo não é um mp3");
        return;
      }
      this.sending = true;

      const file = event;

      if (!file) {
        return;
      }
      if (file.size > 10000000) {
        alert("Arquivo deve ser menor que 1MB");
        return;
      }

      const concatArrayBuffer = (arrayBuffer1, arrayBuffer2) => {
        let tmp = new Uint8Array(
          arrayBuffer1.byteLength + arrayBuffer2.byteLength
        );
        tmp.set(new Uint8Array(arrayBuffer1), 0);
        tmp.set(new Uint8Array(arrayBuffer2), arrayBuffer1.byteLength);
        return tmp.buffer;
      };

      // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      const divider = 512;

      let filename = file.name;
      // pegando o tamanho do nome
      let nameSize = new Uint8Array(1);
      nameSize.set([filename.length], 0);
      // pegando o nome em binario
      let nameBuff = new TextEncoder().encode(filename);

      let nameWithSize = concatArrayBuffer(nameSize, nameBuff);

      let reader = new FileReader();
      let rawData = new ArrayBuffer();
      reader.onload = async (e) => {
        // envia parcelado kkkkkk
        rawData = e.target.result;
        this.parcelado.data = rawData;
        this.parcelado.name = nameWithSize;
        this.parcelado.index = 0;
        this.parcelado.total = Math.ceil(rawData.byteLength / divider);
        this.parcelado.filename = filename;
        this.parcelado.slices = [];
        for (let index = 0; index < this.parcelado.total; index++) {
          const element = this.parcelado.data.slice(
            index * divider,
            (index + 1) * divider
          );
          this.parcelado.slices.push(concatArrayBuffer(nameWithSize, element));
        }
        this.sendParcelado();
      };

      reader.readAsArrayBuffer(file);
    },
    async sendParcelado() {
      await this.delay(50);
      console.log(this.uploadProgress, "this.uploadProgress");
      if (this.uploadProgress >= 99.9) {
        console.log("terminou");
        this.parcelado.data = [];
        this.parcelado.name = "";
        this.parcelado.index = 0;
        this.parcelado.total = 0;
        this.parcelado.filename = "";
        this.parcelado.slices = [];
        return;
      }
      let pct = (this.parcelado.index / this.parcelado.total) * 100;
      this.uploadProgress = pct;
      console.log("enviando", this.parcelado.index, this.parcelado.total, pct);
      if (this.ws != null) {
        this.ws.send(this.parcelado.slices[this.parcelado.index]);
      }
      this.parcelado.index++;
    },
    updateDevice() {
      let aa = this.audios.map((e) => e.name);
      this.getDevice.config.localaudios = aa.join(";");
      this.$store.dispatch("saveDevice", this.getDevice);
      this.disconnect();
      setTimeout(() => {
        this.reconnect();
      }, 5000);
    },
    sendConsole() {
      this.sended.push(this.toDevice);
      this.historyIndex = 0;
      this.send(this.toDevice);
      this.toDevice = "";
    },
    parseTecla({ key }) {
      if (key == "Enter") {
        this.sendConsole();
      }
      if (key == "ArrowUp") {
        if (this.sended.length > 0 && this.historyIndex >= 0) {
          let rev = this.sended.reverse();
          this.toDevice = rev[this.historyIndex];
          if (this.historyIndex < this.sended.length) {
            this.historyIndex++;
          } else {
            this.historyIndex = 0;
          }
        }
      }
      if (key == "ArrowDown") {
        if (this.sended.length >= 0 && this.historyIndex > 0) {
          let rev = this.sended.reverse();
          this.toDevice = rev[this.historyIndex];
          if (this.historyIndex > 0) {
            this.historyIndex--;
          } else {
            this.historyIndex = 0;
          }
        }
      }
      if (key == "Escape") {
        this.toDevice = "";
      }
    },
  },
  watch: {
    getDevice: function (value) {
      if (value && value.id) {
        this.startWs();
      }
    },
  },
  mounted() {
    if (this.$router.currentRoute.query.deviceId) {
      this.$store.dispatch(
        "loadDeviceById",
        this.$router.currentRoute.query.deviceId
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.ws) {
      this.ws.removeEventListener("message", this.receive);
      this.ws.close();
    }
    next();
  },
};
</script>

<style scoped>
.switchSVG {
  width: 150px;
  height: 50px;
  min-width: 150px;
  cursor: pointer;
}

.circle {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  border-color: aqua;
  background-color: rgba(130, 130, 130, 0.1);
  margin: 0 auto;
}

.line {
  display: inline-block;
  width: 40px;
  height: 2px;
  border-style: solid;
  border-color: aqua;
  margin-bottom: 23px;
  padding: -5px;
  transform: scale(1.3, 1);
}

.lineOpen {
  animation: gira 0.5s ease forwards normal;
}

.lineClose {
  animation: gira2 0.5s ease forwards normal;
}

@keyframes gira {
  0% {
    margin-bottom: 23px;
    transform: scale(1.3, 1);
  }
  100% {
    margin-bottom: 43px;
    transform: rotate(-20deg) scale(1.5, 1);
  }
}

@keyframes gira2 {
  0% {
    margin-bottom: 43px;
    transform: rotate(-20deg) scale(1.5, 1);
  }
  100% {
    margin-bottom: 23px;
    transform: rotate(0deg) scale(1.3, 1);
  }
}
</style>